<template>
  <div class="author-page bg-light">
    <main class="main">
      <div v-for="item in authorWallList" :key="item.id" class="wall-block">
        <div class="title">
          <p class="categoryname">{{ item.categoryname }}</p>
          <p class="secondcategoryname">- {{ item.secondcategoryname }} -</p>
        </div>
        <div
          v-if="item.block_type === 1 && item.authorlist.length > 0"
          class="item-block1"
        >
          <div class="publication">
            <div class="leftArrow">
              <img
                src="@/assets/images/arrow-left.png"
                v-if="currentPage"
                @click="arrowClick('left')"
              />
              <img v-else src="@/assets/images/arrow-left-disabled.png" />
            </div>
            <el-carousel
              arrow="never"
              height="698px"
              :autoplay="false"
              :loop="false"
              ref="cardShow"
            >
              <el-carousel-item
                v-for="(m, index) in item.authorlist[currentIndex].yearsinfo"
                :key="index"
              >
                <div class="carousel-box">
                  <el-image
                    class="periodical-image"
                    @contextmenu="handleMouse"
                    :src="m.periodical_image"
                    fit="cover"
                  ></el-image>
                  <div class="periodical-text">
                    <div class="box-top">
                      <div class="line"></div>
                      <p class="date">
                        {{ m.periodical_year }}.{{ m.periodical_month }}月刊
                      </p>
                      <p>封面摄影师</p>
                    </div>
                    <div
                      class="box-bottom"
                      :class="{ 'c-p': m.jump_ucenter === 1 }"
                      @click="jumpUCenter(m)"
                    >
                      <img
                        :src="m.headimage || defaultimg"
                        class="head-image"
                      />
                      <p class="name">{{ m.photographer }}</p>
                      <p class="description">
                        {{ m.photographer_description }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="rightArrow">
              <img
                src="@/assets/images/arrow-right.png"
                @click="arrowClick('right')"
                v-if="
                  item.authorlist[currentIndex].yearsinfo.length !==
                    currentPage + 1
                "
              />
              <img v-else src="@/assets/images/arrow-right-disabled.png" />
            </div>
          </div>
          <ul class="year-tab">
            <li
              v-for="(n, index) in item.authorlist"
              :key="index"
              @click="changeYear(n, index)"
            >
              <p v-if="index < 10" :class="{ active: currentIndex === index }">
                {{ n.periodical_year }}
              </p>
            </li>
          </ul>
        </div>
        <div
          v-if="item.block_type === 2 || item.block_type === 3"
          :class="item.block_type === 2 ? 'item-block2' : 'item-block3'"
        >
          <div
            v-for="(list, index) in item.authorlist"
            :key="index"
            class="col"
            :class="{ 'c-p': list.jump_ucenter === 1 }"
            @click="jumpUCenter(list)"
          >
            <div class="col-p">
              <img :src="list.headimage" class="head-image" />
              <div class="detail">
                <p class="name">{{ list.photographer }}</p>
                <p class="description">{{ list.photographer_description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "authorWall",
  data() {
    return {
      defaultimg: require("../../assets/images/headPortrait.png"),
      authorWallList: [],
      currentIndex: 0,
      yearsInfo: [],
      currentPage: 0
    };
  },
  async mounted() {
    const { data } = await this.$api.getAuthorWallList();
    if (data.code === 1) {
      this.authorWallList = data.data;
    }
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    arrowClick(val) {
      if (val === "right") {
        this.currentPage++;
        this.$refs.cardShow[0].next();
      } else {
        this.currentPage--;
        this.$refs.cardShow[0].prev();
      }
    },
    changeYear(item, index) {
      this.currentIndex = index;
      this.currentPage = 0;
    },
    jumpUCenter(user) {
      const { jump_ucenter, user_id } = user;
      if (jump_ucenter === 1) {
        this.$router.push({
          name: "personalCenter",
          query: { PageUserId: user_id }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.author-page {
  padding: 0 0px 50px;
  color: #000000;
  min-height: 100%;
  .main {
    margin: 0 auto;
  }
  .wall-block {
    padding-top: 136px;
    .c-p {
      cursor: pointer;
    }
    .title {
      text-align: center;
      margin-bottom: 39px;
      .categoryname {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000700;
        margin-bottom: 16px;
      }
      .secondcategoryname {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .item-block1 {
    display: flex;
    padding: 0 15px 30px;
    .publication {
      flex: 1;
      margin-right: 30px;
      height: 698px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 16px 26px 17px 0 rgba(0, 0, 0, 0.15);
      position: relative;
      .leftArrow,
      .rightArrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        img {
          width: 52px;
          height: 71px;
        }
      }
      .leftArrow {
        left: 48px;
      }
      .rightArrow {
        right: 48px;
      }
      .carousel-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15%;
        height: 100%;
        .periodical-image {
          width: 381px;
          height: 535px;
        }
        .periodical-text {
          height: 535px;
          width: 268px;
        }
        .head-image {
          width: 119px;
          height: 119px;
          border-radius: 50%;
        }
        .box-top {
          text-align: right;
          .line {
            width: 60px;
            height: 3px;
            background: #e60021;
            display: inline-block;
          }
          .date {
            margin: 15px 0 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
        }
        .box-bottom {
          margin-right: 60px;
          text-align: center;
          margin-top: 90px;
          .name {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            margin: 87px 0 25px;
          }
          .description {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #000000;
            // width: 268px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
    .year-tab {
      width: 100px;
      background: #000000;
      border-radius: 10px;
      color: #ffffff;
      padding: 14px 0;
      height: 698px;
      p {
        padding: 24px 0;
        text-align: center;
        cursor: pointer;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .active {
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #e60021;
      }
    }
  }
  .item-block2 {
    overflow: hidden;
    .col {
      width: 20%;
      float: left;
      text-align: center;
      padding: 0 15px;
      margin-bottom: 29px;
      .col-p {
        background: #ffffff;
        height: 354px;
        padding: 50px 20px 30px;
        border-radius: 10px;
        .head-image {
          width: 121px;
          height: 121px;
          border-radius: 50%;
        }
        .name {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin: 25px 0 30px;
        }
        .description {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #000000;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          line-height: 20px;
        }
      }
    }
  }
  .item-block3 {
    .col {
      width: 25%;
      display: inline-block;
      text-align: center;
      margin-bottom: 40px;
      padding: 0 10px;
      .col-p {
        display: flex;
        align-items: center;
        text-align: left;
        .head-image {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          margin-right: 18px;
        }
        .detail {
          width: calc(100% - 100px);
        }
        .name {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-bottom: 6px;
        }
        .description {
          font-family: PingFang SC;
          color: #000000;
          line-height: 20px;
          font-size: 12px;
          font-weight: 300;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // width: 86%;
        }
      }
    }
  }
}
// 媒体查询 小于等于某个宽度
@media screen and (max-width: 1339px) {
  .author-page {
    .main {
      width: 1060px;
    }
    .item-block1 {
      .publication {
        flex: 1;
        margin-right: 30px;
        height: 698px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 16px 26px 17px 0 rgba(0, 0, 0, 0.15);
        .carousel-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15%;
          height: 100%;
          .periodical-image {
            width: 310px;
            height: 535px;
          }
          .periodical-text {
            height: 535px;
            width: 240px;
          }
          .head-image {
            width: 119px;
            height: 119px;
            border-radius: 50%;
          }
          .box-top {
            text-align: right;
            .line {
              width: 60px;
              height: 3px;
              background: #e60021;
              display: inline-block;
            }
            .date {
              margin: 15px 0 10px;
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
            }
          }
          .box-bottom {
            margin-right: 60px;
            text-align: center;
            margin-top: 90px;
            .name {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
              margin: 87px 0 25px;
            }
            .description {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #000000;
              // width: 268px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              line-height: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
// 媒体查询 大于等于某个宽度
@media screen and (min-width: 1080px) {
  .author-page {
    .main {
      width: 1060px;
    }
  }
}
@media screen and (min-width: 1340px) and (max-width: 1921px) {
  .author-page {
    .main {
      width: 1312px;
    }
  }
}
@media screen and (min-width: 1921px) {
  .author-page {
    .main {
      width: 1312px;
    }
  }
}
</style>
